import { Injectable, computed, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, delay, map, of, take } from 'rxjs';

import { DELAY_TIME } from '../../../constants/app.constant';
import { RpcMessagesBackend } from '../../../enums/rpc-messages.enum';
import { Status } from '../../../enums/status.enum';
import { PixelStreamingService } from '../../../services/pixelstreaming.service';
import { GetLevelsResult, OpenLevelByNameParam, SUCCESS } from '../../../types/jsonrpc.interface';
import { successMap } from '../../../utils/rxjs-custom-pipes/success-map.util';
import { FeatureFlagService } from '../../feature-flag/feature-flag.service';
import { Level } from './levels.interface';
import { FROM_MOCKS, MAIN_MAP_LEVEL_NAME, mockLevels } from './levels.mocks';
import { getLevels, openLevelByName } from './store/levels.actions';
import { LevelsState } from './store/levels.reducer';
import { selectCurrentLevel, selectLevels, selectLevelsLoadingStatus } from './store/levels.selectors';

/**
 * Сервис для обработки данных и операций с уровнями.
 *
 * @@Injectable({ providedIn: 'root' })
 */
@Injectable({ providedIn: 'root' })
export class LevelsService {
  /**
   * Получает уровни с использованием функции selectSignal() из объекта store$.
   *
   * @name levels
   * @type {Observable}
   */
  levels = this.store$.selectSignal(selectLevels);
  /**
   * Представляет статус загрузки конкретного ресурса.
   *
   * @typedef {Observable<boolean>} LoadingStatus
   */
  loadingStatus = this.store$.selectSignal(selectLevelsLoadingStatus);

  /**
   * Представляет текущую переменную уровня.
   * Используется для подписки на сигнал selectCurrentLevel, который передается объектом 'store$'.
   *
   * @type {Observable<CurrentLevel>}
   * @public
   */
  currentLevel = this.store$.selectSignal(selectCurrentLevel);

  /**
   * Проверяет, является ли текущее местоположение главным.
   * @returns {boolean} Возвращает true, если текущий уровень является главным, в противном случае возвращает false.
   */
  isMainLocation = this.featureFlagService.isFeatureOn('LEVELS')
    ? computed(() => this.currentLevel()?.name === MAIN_MAP_LEVEL_NAME)
    : signal(false);

  /**
   * Конструктор класса.
   *
   * @param {Store<LevelsState>} store$ - Хранилище состояния уровней.
   * @param {PixelStreamingService} pixelStreamingService - Сервис потоковой передачи пикселей.
   * @param {FeatureFlagService} featureFlagService - Сервис флагов функциональности.
   */
  constructor(
    private store$: Store<LevelsState>,
    private pixelStreamingService: PixelStreamingService,
    private featureFlagService: FeatureFlagService,
  ) {}

  /**
   * Выполняет действие получения уровней.
   *
   * @returns {void}
   */
  getLevelsAction(): void {
    this.loadingStatus() === Status.UNINITIALIZED && this.store$.dispatch(getLevels());
  }

  /**
   * Открывает уровень, определенный его именем.
   *
   * @param {Level} level - Уровень для открытия.
   *
   * @return {void}
   */
  openLevelByNameAction(level: Level): void {
    this.store$.dispatch(openLevelByName({ level, fromMocks: FROM_MOCKS }));
  }

  /**
   * Получает уровни либо из макетов, либо от службы Pixel Streaming.
   * @param {boolean} fromMocks - Получать ли уровни из макетов или службы Pixel Streaming.
   * @returns {Observable<Level[]>} - Наблюдаемый объект, который излучает массив объектов Level.
   */
  getLevels(fromMocks = FROM_MOCKS): Observable<Level[]> {
    if (!this.featureFlagService.isFeatureOn('LEVELS')) {
      return of([]);
    }

    if (fromMocks) {
      return of(mockLevels).pipe(delay(DELAY_TIME));
    } else {
      return this.pixelStreamingService
        .sendRequest<{ result: GetLevelsResult }>(RpcMessagesBackend.GET_LEVELS_LIST)
        .pipe(map((data) => data.result?.levels ?? []));
    }
  }

  /**
   * Открывает уровень по его имени.
   *
   * @param {string} levelName - Имя открываемого уровня.
   * @param {boolean} [fromMocks=FROM_MOCKS] - Использовать ли макеты для тестирования. По умолчанию FROM_MOCKS.
   * @returns {Observable<SUCCESS>} - Наблюдаемый объект, который излучает ответ об успехе при открытии уровня.
   * @throws {Error} - Если в ответе нет свойства success или это свойство false.
   */
  openLevelByName(levelName: string, fromMocks = FROM_MOCKS): Observable<SUCCESS> {
    if (fromMocks) {
      return of({ success: true }).pipe(delay(DELAY_TIME));
    }

    return this.pixelStreamingService
      .sendRequest<{ params: OpenLevelByNameParam; result: SUCCESS }>(RpcMessagesBackend.OPEN_LEVEL_BY_NAME, { level: levelName })
      .pipe(successMap('openLevelByName'), take(1));
  }
}
